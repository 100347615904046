import React from "react";
//import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import * as Components from "src/components";
import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import { Container, Grid } from "@mui/material";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

const card1 = (
  <>
    <CardContent>
      <Typography variant="h4" gutterBottom color="black">
        <span className="font-bold">Vorstand</span>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        <span className="font-bold">
          1. Vorsitzender
          <br />
          Ralf Winkler
        </span>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <img
            src={require("src/files/Bild_Ralf.jpg")}
            width="25%"
            height="auto"
            alt="Bild vom Vorstand"
          />
        </div>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        Eintritt in den Verein 01.07.2012
        <br />
        Im Vorstand seit Januar 2015
        <br />
        Lieblingsdisziplin: 8- Ball (auch wenn ohne Erfolg)
        <br /> <MailIcon />{" "}
        <a href={"mailto:ralf.winkler@pbv-pinneberg.de"}>
          ralf.winkler@pbv-pinneberg.de
        </a>
      </Typography>
      <hr />
      <Typography sx={{ mb: 1.5 }}>
        <span className="font-bold">
          2. Vorsitzender
          <br />
          Jan Bitter
        </span>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <img
            src={require("src/files/Bild_Jan.jpg")}
            width="25%"
            height="auto"
            alt="Bild vom Vorstand"
          />
        </div>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        Eintritt in den Verein 01.09.2020
        <br />
        Im Vorstand seit Mai 2023
        <br />
        Lieblingsdisziplin: 9-Ball
        <br />
        <MailIcon />{" "}
        <a href={"mailto:jan.bitter@pbv-pinneberg.de"}>
          jan.bitter@pbv-pinneberg.de
        </a>
      </Typography>
      <hr />
      <Typography sx={{ mb: 1.5 }}>
        <span className="font-bold">
          Kassenwart
          <br />
          Michael Sturhan
        </span>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <img
            src={require("src/files/Bild_Michael.jpg")}
            width="25%"
            height="auto"
            alt="Bild vom Vorstand"
          />
        </div>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        Eintritt in den Verein 01.07.2012
        <br />
        Im Vorstand seit September 2021
        <br />
        Lieblingsdisziplin: Snooker
        <br />
        <MailIcon />{" "}
        <a href={"mailto:michael.sturhan@pbv-pinneberg.de"}>
          michael.sturhan@pbv-pinneberg.de
        </a>
      </Typography>
      <hr />
      <Typography sx={{ mb: 1.5 }}>
        <span className="font-bold">
          Sportwart Pool
          <br />
          Frank Schmidt
        </span>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <img
            src={require("src/files/Bild_Frank.jpg")}
            width="25%"
            height="auto"
            alt="Bild vom Vorstand"
          />
        </div>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        Eintritt in den Verein 20.02.2002
        <br />
        Im Vorstand seit Juli 2018
        <br />
        Lieblingsdisziplin: 10 Ball
        <br />
        <MailIcon />{" "}
        <a href={"mailto:frank.schmidt@pbv-pinneberg.de"}>
          frank.schmidt@pbv-pinneberg.de
        </a>
      </Typography>
      <hr />
      <Typography sx={{ mb: 1.5 }}>
        <span className="font-bold">
          Sportwart Snooker
          <br />
          Joaquim Macedo
        </span>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <img
            src={require("src/files/Bild_Joaquim.jpg")}
            width="25%"
            height="auto"
            alt="Bild vom Vorstand"
          />
        </div>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        Eintritt in den Verein 01.06.2018
        <br />
        Im Vorstand seit Juni 2022
        <br />
        Lieblingsdisziplin: Snooker
        <br />
        <MailIcon />{" "}
        <a href={"mailto:joaquim.macedo@pbv-pinneberg.de"}>
          joaquim.macedo@pbv-pinneberg.de
        </a>
      </Typography>
      <hr />
      <Typography sx={{ mb: 1.5 }}>
        <span className="font-bold">
          Sonderaufgaben
          <br />
          Arne Lorenz
        </span>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <img
            src={require("src/files/Bild_Arne.jpg")}
            width="25%"
            height="auto"
            alt="Bild vom Vorstand"
          />
        </div>
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        Eintritt in den Verein 24.02.2010
        <br />
        Im Vorstand seit Juni 2022
        <br />
        Lieblingsdisziplin: 14/1
        <br />
        <MailIcon />{" "}
        <a href={"mailto:arne.lorenz@pbv-pinneberg.de"}>
          arne.lorenz@pbv-pinneberg.de
        </a>
      </Typography>
    </CardContent>
  </>
);

const Vorstand: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Vorstand;
