import React from "react";
import { Card, Container, Grid } from "@mui/material";

const PbvBild: React.VFC = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card variant="outlined" sx={{ marginBottom: 2 }}>
              <img
                src={require("src/files/PBV_Header.png")}
                width="100%"
                height="auto"
                alt="PBV Bild"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PbvBild;
