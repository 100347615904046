import React from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import * as Components from "src/components";
//import { CardKonfiguration } from "src/utils";

const card1 = (
  <>
    <CardContent>
      <Typography sx={{ marginBottom: -1, color: "black" }}>
        Unsere Vereinchronik in Langversion,&nbsp;
        <Link
          style={{ color: "red", fontWeight: "bold" }}
          underline="none"
          // className="text-gray-400"
          href="/ChronikKurz"
        >
          für die Kurzversion bitte hier klicken.
        </Link>
      </Typography>
    </CardContent>
  </>
);

const card2 = (
  <>
    <CardContent>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Unser Verein wurde im Jahr 1997 von knapp einem Dutzend Enthusiasten des
        Billardsports gegründet. Ansässig waren wir zunächst im Billard Cafe
        „Hot Shots“, das sich zu der damaligen Zeit in Pinneberg in der
        Apenrader Straße befand, also nur ein paar Meter von unserem heutigen
        Vereinsheim entfernt. Schnell wuchs der Verein heran, doch genauso
        schnell mussten wir zum ersten Mal feststellen, dass es auch
        entscheidende Nachteile gibt, wenn man in einem Billardcafe
        Nutzungsrechte hat.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Eine deutliche Verschlechterung durch den Cafebetreiber zwang uns zu
        unserem ersten Umzug. Fortan befand sich der Verein im Pinneberger
        Billardcafe „Sharkys“.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Wenige Jahre später musste diese Prozedur erneut durchlaufen werden und
        wir wechselten in das Billardcafe „Wallis“ ebenfalls in Pinneberg.{" "}
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Dreimal reicht? Könnte man glauben, aber auch dort war der Verein nur
        wenige Jahre, um dann ganz die Stadt zu verlassen und in
        Hamburg-Eidelstedt unterzukommen. Zu dem Zeitpunkt war der Verein sehr
        stark an Mitgliedern geschrumpft und obendrein fern der Heimat - aber er
        hatte eine Vision. Mit sehr viel Mut und Einsatz wurde der Schritt
        umgesetzt, dem Verein ein eigenes Vereinsheim zu geben, um fortan
        unabhängig zu sein und langfristig planen zu können.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Im Jahr 2011 wurde im Gewerbegebiet Pinneberg Nord eine passende Bleibe
        in der Haderslebener Straße gefunden. Zunächst mit knapp 360
        Quadratmetern und neun Pooltischen ausgestattet, startete eine
        Entwicklung, die ihresgleichen sucht. Denn schon nach kurzer Zeit wurde
        ersichtlich, dass der Verein mit dem eigenen Vereinsheim eine sehr große
        Anziehung auf Spieler der Region ausübte. Da man nie aufhören sollte zu
        träumen und sich immer wieder neue Ziele setzen sollte, wurde der
        nächste mutige Schritt unternommen.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Die angrenzende Fläche wurde dazu gemietet und das Vereinsheim wuchs von
        360 auf 670 Quadratmeter. Wieder haben die Mitglieder kräftig angepackt
        und von ihrer Zeit und ihrem Können fleißig gegeben; und so konnte ein
        großartiges Vereinsheim entstehen mit 17 Pool- und 4 Snookertischen.{" "}
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Im Mai 2013 war es geschafft und der Traum Wirklichkeit. Gebührend
        wurden die Räume mit einem Eröffnungsturnier mit einem bis dahin noch
        nie dagewesenen Teilnehmerfeld von 128 Spielern eingeweiht. Doch die
        schwerste Prüfung sollte erst noch kommen…
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Nur knapp einen Monat nach der Eröffnung kam es durch ein Gewitter zu
        einem Kurzschluss im Vereinsheim und die Räume samt Inhalt wurden durch
        einen Brand komplett vernichtet. Die knapp 50 Mitglieder trafen sich
        danach zu einer historischen Sitzung und entschieden, dass fast
        Unmögliche stemmen zu wollen, nämlich das Vereinsheim wieder aufzubauen.
        Die folgenden Monate sind kaum in Worte zu fassen, denn was von den
        Mitgliedern da geleistet wurde war unbeschreiblich. Dank eines
        fantastischen Einsatzes der Mitglieder, die tatsächlich fast komplett
        über die Monate ohne Vereinsheim zum Billardspielen dem Verein die Treue
        hielten, dank auch der Hilfe vieler anderer Billardfreunde sogar über
        die Grenzen Deutschlands hinaus, wurde das Vereinsheim ein zweites Mal
        erschaffen. Noch besser und schöner. Und so steht es heute da, zur
        Nutzung für alle Billardfans und zur Umsetzung von allen
        Billardveranstaltungen, die man gerne einmal erlebt haben möchte.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Dieser Spirit, der dem Verein innewohnt, ist auch heute noch vorhanden.
        Darum sind wir stolz auf das, was wir geschaffen haben, dass wir es mit
        unserem Fleiß erreicht haben und dass der Verein wirklich uns gehört,
        den Mitgliedern.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Und so ist es möglich, dass wir im Jahr 2022 das 25-jährige Bestehen des
        1. PBV Pinneberg feiern dürfen.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Nach dem die Pandemie nun hinter uns liegt, haben wir im Sommer 2023
        unser Vereinsheim umgebaut und erweitert. Wir haben nun neu 6
        Snookertische und 16 Pooltische.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Auf das, was da noch kommt…!
      </Typography>
    </CardContent>
  </>
);

const ChronikLang: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card2}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ChronikLang;
