import React from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
// import { NavLink } from "react-router-dom";
// import LinkIcon from "@mui/icons-material/Link";
import WebIcon from "@mui/icons-material/Web";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

const card1 = (
  <>
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">BILLARD NIGHT</span>
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">jeden letzten Freitag im Monat</span>
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">19.00 UHR</span>
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">10€ STARTGELD - 6,50€ ESSEN</span>
          </Typography>
          <Typography
            // sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
            sx={{
              mb: 1.5,
              color: "black",
              textAlign: "center",
            }}
          >
            Anmelden könnt Ihr Euch über die Seite von tinyurl &#040;
            <a
              style={{ color: "red" }}
              href="https://tinyurl.com/IchWillMitspielen"
              target="_blank"
            >
              Link zur Seite: <WebIcon />
            </a>
            &#041;
          </Typography>
          <Typography
            // sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
            sx={{
              mb: 1.5,
              textAlign: "center",
              color: "black",
            }}
          >
            oder über die tournamentapp &#040;
            <a
              style={{ color: "red" }}
              href="https://www.tournamentapp.de/kalender.php?s=&suche=pinneberg"
              target="_blank"
            >
              Link zur Seite: <WebIcon />
            </a>
            &#041;.
          </Typography>
          <Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
                marginBottom: 10,
              }}
            >
              <img
                src={require("src/files/bnNightNeu.png")}
                width="100%"
                height="auto"
                alt="Bild vom der Billard Night 3"
              />
            </div>
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </>
);

const BN: React.VFC = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BN;
