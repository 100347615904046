import React from "react";
import { Card, Container, Grid } from "@mui/material";
import * as Components from "src/components";
import BN from "src/components/bn";
import DasTurnier from "src/components/dasTurnier";

const Turniere: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            {/* <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <Components.Friday />
            </Card> */}
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <DasTurnier />
            </Card>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <BN />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Turniere;
