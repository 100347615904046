import React from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import WebIcon from "@mui/icons-material/Web";

const card1 = (
  <>
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">Das Turnier</span>
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">am 30.08.2024</span>
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">ab 19.00 UHR</span>
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            color="black"
            sx={{ textAlign: "center" }}
          >
            <span className="font-bold">
              20€ STARTGELD, 100% werden ausgeschüttet
            </span>
          </Typography>
          <Typography
            // sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
            sx={{
              mb: 1.5,
              color: "black",
              textAlign: "center",
            }}
          >
            Anmelden könnt Ihr Euch über die Seite von tinyurl &#040;
            <a
              style={{ color: "red" }}
              href="https://tinyurl.com/IchWillMitspielen"
              target="_blank"
            >
              Link zur Seite: <WebIcon />
            </a>
            &#041;
          </Typography>
          <Typography
            // sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
            sx={{
              mb: 1.5,
              textAlign: "center",
              color: "black",
            }}
          >
            oder über die tournamentapp &#040;
            <a
              style={{ color: "red" }}
              href="https://www.tournamentapp.de/kalender.php?s=&suche=pinneberg"
              target="_blank"
            >
              Link zur Seite: <WebIcon />
            </a>
            &#041;.
          </Typography>
          <Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
                marginBottom: 10,
              }}
            >
              <img
                src={require("src/files/dasTurnier.jpg")}
                width="100%"
                height="auto"
                alt="Bild vom Flayer Das Turnier"
              />
            </div>
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </>
);

const DasTurnier: React.VFC = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DasTurnier;
