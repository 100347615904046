const cardKonfig = {
  anzSpalten: 12,
  anzSpaltenSM: 12,
  // abstand: 3,
  verhaeltnisText: 10,
  verhaeltnisDatum: 2,
  hintergrundfarbarbe: "lightgray",
  hintergrundfarbarbeDatum: "gray",
  schriftfarbe: "black",
  schriftGroesse: 16,
  bgHeadline: "crimson",
  displayFlex: "flex",
  alignItemsStretch: "stretch",
  justifyContentCenter: "center",
  marginRight1: 1,
  marginLeft1: 1,
  marginTopMinus1: -1,
  paddingTopGrid: 1,
};
export default cardKonfig;
