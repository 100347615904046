import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import MailIcon from "@mui/icons-material/Mail";
import { FridayChallenge } from "src/utils";
import fridayDate from "src/utils/fridayChallenge";
import preise from "src/utils/preise";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const card1 = (
  <>
    <CardContent>
      <Card variant="outlined" sx={{ marginBottom: 2, marginLeft: -1 }}>
        <img
          src={require("src/files/friday_ich_bin_dabei.png")}
          width="100%"
          height="auto"
          alt="Bild ich bin dabei Friday Challenge"
        />
      </Card>
      <Typography variant="h3" component="div" color="red">
        Save{bull}The{bull}Date, {FridayChallenge.dateFridayChallenge}
      </Typography>
      <Typography variant="h5" component="div" sx={{ mt: 2, color: "black" }}>
        Wir{bull}spielen{bull}ein{bull}letztes{bull}mal{bull}unter{bull}der
        {bull}Regie{bull}von{bull}Borsti{bull}und{bull}Stefan{bull}diese{bull}
        Turnier.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Ein Turnier für die Rookies (Spielstärke bis Kreisliga) und ein Turnier
        für die Advanced (Spielstärke bis Landesliga).
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Rookies: 16 Teilnehmer mit minddestens 3 Teilnahmen in der Serie.
        Anmeldungen werden laut Rangliste von oben nach unten in der Wertigkeit
        berücksichtigt.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Advanced: 32 Teilnehmer mit minddestens 3 Teilnahmen in der Serie.
        Anmeldungen werden laut Rangliste von oben nach unten in der Wertigkeit
        berücksichtigt.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Wenn ihr euch nicht sicher seid, ob ihr spielberechtigt seid, dann bitte
        vorher melden!
      </Typography>
      <Typography variant="h5" component="div" color="black" sx={{ mt: 2 }}>
        {/* Welchen Ball wir spielen wird jetzt noch nicht verraten. Lasst Euch
        überraschen! */}
        Gespielt wird dieses Mal {fridayDate.ballToPlay}. Lasst Euch
        überraschen! Alles kann passieren!
      </Typography>
      <Typography variant="h5" component="div" color="black" sx={{ mt: 2 }}>
        Anmeldung diesmal unter <MailIcon />{" "}
        <a href={"mailto:fridaychallenge@pbv-pinneberg.de"}>
          fridaychallenge@pbv-pinneberg.de
        </a>{" "}
        oder unter +49 1522 9444 952,{" "}
        <span style={{ color: "red" }}>wichtig nur per WhatsApp oder SMS.</span>
      </Typography>
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        Wenn ihr nach Anmeldung dann doch nicht könnt, dann reißt euch keiner
        den Kopf ab ...
      </Typography> */}
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        ... einfach kurz Bescheid sagen und gut ist. Solltet ihr Probleme bei
        der Online-Anmeldung haben, dann meldet euch gerne bei uns!
      </Typography> */}
      {/* <Typography variant="h3" component="div" color="black">
        WEITERER{bull}BONUS{bull}FÜR{bull}FRÜHBUCHER
      </Typography> */}
      {/* <Typography
        sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
      >
        <span style={{ color: "red" }}>
          JETZT online anmelden und nur € 8,50 statt € 10,- Startgeld bezahlen!
        </span>{" "}
      </Typography> */}
      {/* <Typography
        sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
      >
        <span style={{ color: "red" }}>
          JETZT online anmelden und wie früher nur € {preise.startgeldFriday},-
          Startgeld bezahlen!
        </span>{" "}
      </Typography> */}
      {/* <Typography
        sx={{ mb: 1.5, color: "red", fontWeight: "bold", fontSize: 20 }}
      >
        Der Termin wird bald online verfügbar sein. Also habt bitte noch etwas
        Geduld und schaut immer mal wieder für die Anmeldung vorbei.
      </Typography> */}
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        Die Differenz von € 1,50 wird trotzdem als Preisgeld ausgeschüttet, da
        sie von unserem Verein als added-money hinzugefügt wird!
      </Typography> */}
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        Dieses Angebot gilt nur noch kurze Zeit, alle Anmeldungen danach
        entrichten das normale Startgeld.
      </Typography> */}
      <p />
      {/* <Typography
        sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
      >
        Anmelden könnt Ihr Euch über die Seite von tinyurl &#040;
        <a
          style={{ color: "red" }}
          href="https://tinyurl.com/IchWillMitspielen"
          target="_blank"
          rel="noopener"
        >
          Link zur Seite: <WebIcon />
        </a>
        &#041;
      </Typography> */}
      {/* <Typography
        sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 20 }}
      >
        oder über die tournamentapp &#040;
        <a
          style={{ color: "red" }}
          href="https://www.tournamentapp.de/kalender.php?s=&suche=pinneberg"
          target="_blank"
          rel="noopener"
        >
          Link zur Seite: <WebIcon />
        </a>
        &#041;.
      </Typography> */}
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        <Typography sx={{ mb: 1.5, color: "black" }}>
          Bitte beachtet unbedingt den{" "}
          <span style={{ color: "red" }}>Meldeschluss (Online)</span> für die
          FridayChallenge. Dieser ist{" "}
          <span style={{ color: "red" }}>immer am Mittwoch (23:59 Uhr)</span>{" "}
          vor dem Termin.
        </Typography>
        Wir freuen uns auf Euch und Eure zahlreichen Anmeldungen.
      </Typography> */}
      {/* <Typography variant="h3" component="div" color="black">
        {bull}Das{bull}bietet{bull}Euch{bull}unsere{bull}Küche{bull}made{bull}by
        {bull}STEPHIE{bull}
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <ul>
          <li>- ab 17:30 Uhr geöffnet</li>
          <li>- mehrere vollwertige Speisen</li>
          <li>- wahlweise warm oder kalt</li>
        </ul>
        <Typography sx={{ mb: 1.5, color: "black", fontWeight: "bold" }}>
          im Voraus vielen Dank an Stephie!!
        </Typography>
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Am Tresen wird euch einer unserer besten Spieler begrüßen, der aufgrund
        von Über-Kompetenz nicht selbst mitspielt… lasst euch überraschen, wer
        es ist…
      </Typography> */}
      <Typography variant="h3" component="div" color="black">
        Und{bull}jetzt{bull}RAN{bull}AN{bull}DIE{bull}TASTATUR{bull}UND
        {bull}ANMELDEN
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Gerne erreicht Ihr uns auch per Mail
        <br /> <MailIcon />{" "}
        <a href={"mailto:fridaychallenge@pbv-pinneberg.de"}>
          fridaychallenge@pbv-pinneberg.de
        </a>
      </Typography>
      <Typography variant="h5" color="black">
        Euer{bull}Friday{bull}Challenge{bull}Team
      </Typography>
      {/* <Typography variant="h5" color="black">
        Torsten{bull}Andreas{bull}Stefan
      </Typography> */}
    </CardContent>
  </>
);

const Friday: React.VFC = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          {/* <Card variant="outlined" sx={{ marginBottom: 2, marginTop: 2 }}>
            <img
              src={require("src/files/Achtung.png")}
              width="100%"
              height="auto"
            />
          </Card> */}
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Friday;
