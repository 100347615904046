import React from "react";
//import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import * as Components from "src/components";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

const card1 = (
  <>
    <CardContent>
      <Typography sx={{ fontSize: 28, color: "black" }} gutterBottom>
        Training
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Wir bieten wöchentlich ein Billard-Trainingskonzept für Jugendliche und
        Anfänger an:
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Jeden Mittwoch zwischen 17:00 und ca. 20:00 Uhr steht Geronimo Hornung
        als externer Trainer zur Verfügung, um den Jugendlichen des Vereins 
        (und nach Voranmeldung auch jugendlichen Nichtmitgliedern) Basics und weitere Lernschritte zu vermitteln.
      </Typography>
      <Typography
        sx={{ mb: 1.5, color: "black", fontWeight: "bold", fontSize: 18 }}
      >
        <span style={{ color: "red" }}>Wichtig:</span> 3 Teilnahmen an diesen
        Trainings sind für jugendliche Nicht-Mitglieder kostenlos! Danach sollte sich jeder
        Interessent entscheiden, ob er Mitglied wird oder weiter als Gast bei
        uns spielen wird.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Für die Zukunft sind auch weitere Trainings, auch für Erwachsene und
        Fortgeschrittene geplant. Nähere Infos hierzu folgen zeitnah hier.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Damit wir besser planen können 
        <span style={{ fontWeight: "bold", color: 'red'}}>
        {" "}schickt bitte die Voranmeldung per Mail{" "}
        </span>
         an <MailIcon />{" "}
        <a href={"mailto:vorstand@pbv-pinneberg.de"}>
          <span style={{ fontWeight: "bold" }}>vorstand@pbv-pinneberg.de</span>
        </a>
        .
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Sportliche Grüße und bis bald vielleicht? 😉
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Euer Team Pinneberg
      </Typography>
    </CardContent>
  </>
);

const Satzung: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Satzung;
