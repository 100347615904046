import React from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import * as Components from "src/components";
import { CardKonfiguration } from "src/utils";

const card1 = (
  <>
    <CardContent>
      <Typography sx={{ marginBottom: -1, color: "black" }}>
        Unsere Vereinchronik,&nbsp;
        <Link
          style={{ color: "red", fontWeight: "bold" }}
          underline="none"
          // className="text-gray-100"
          href="/ChronikLang"
        >
          für die Langversion bitte hier klicken.
        </Link>
      </Typography>
    </CardContent>
  </>
);

const gridStyleTop = {
  backgroundColor: CardKonfiguration.hintergrundfarbarbeDatum,
  color: CardKonfiguration.schriftfarbe,
  paddingTop: 1,
  paddingBottom: 1,
  borderRadius: 1,
};

const gridStyleFollowGrids = {
  ...gridStyleTop,
  marginTop: 1,
};

const cardStyleTop = {
  backgroundColor: CardKonfiguration.hintergrundfarbarbe,
  color: CardKonfiguration.schriftfarbe,
  display: CardKonfiguration.displayFlex,
  // alignItems: CardKonfiguration.alignItemsStretch,
  justifyContent: CardKonfiguration.justifyContentCenter,
  marginRight: CardKonfiguration.marginRight1,
  marginLeft: CardKonfiguration.marginLeft1,
};

const cardStyleFollowCards = {
  ...cardStyleTop,
  marginTop: CardKonfiguration.marginTopMinus1,
};

const ChronikKurz: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl" sx={{ marginBottom: 2 }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={CardKonfiguration.anzSpalten}
            sm={CardKonfiguration.anzSpaltenSM}
          >
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleTop }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleTop }}>
                25. Mai 1997
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleTop }}>
                Gründung des 1. PBV Pinneberg e.V. im Billard Cafe “Hot Shot”
                (später „Blümchens Roadhouse“), Apenrader Straße 26
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                1997 – 2011
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                diverse Spielstätten:
                <div style={{ paddingLeft: 5 }}>
                  <ul>
                    <li>• Blümchens Roadhouse, Apenrader Straße 26</li>
                    <li>• Sharky’s, Am Hafen 3</li>
                    <li>• Wallis, Schauenburger Straße 3</li>
                    <li>• Checker’s, Kieler Straße 573 b</li>
                  </ul>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                2011
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Anmietung des heutigen Vereinsheims an der Haderslebener Straße
                4a
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                19.-21. August 2011
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                1. Pinneberg Open
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Juli 2012
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Aufnahme von Mitgliedern und Spielmaterial der Hamburg Flames
                nach deren Auflösung
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                24. Mai 2013
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Eröffnungsturnier Neubau
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                20. Juni 2013
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Brand des Vereinsheims
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                23. November 2013
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Außerordentliche Mitgliederversammlung mit dem Entscheid zum
                Wiederaufbau
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                25. Juni 2014
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Wiedereröffnung Spielbetrieb
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                12. Juli 2014
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Offizielle Wiedereröffnung
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                12.-14. September 2014
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Pinneberg Open
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                14. Februar 2017
              </Card>
            </Grid>
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Begrüßung des 100sten Vereinsmitglieds
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                2022
              </Card>
            </Grid>
            <Grid
              item
              // xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                17 Pooltische, 4 Snookertische, über 80 Mitglieder 25-jähriges
                Bestehen
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={CardKonfiguration.anzSpalten}
          sm={CardKonfiguration.anzSpaltenSM}
          sx={{ ...gridStyleFollowGrids }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: CardKonfiguration.paddingTopGrid }}
          >
            <Grid
              item
              xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisDatum}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Sommer 2023
              </Card>
            </Grid>
            <Grid
              item
              // xs={CardKonfiguration.anzSpalten}
              sm={CardKonfiguration.verhaeltnisText}
            >
              <Card variant="outlined" sx={{ ...cardStyleFollowCards }}>
                Umbau unseres Vereinsheims auf 6 Snookertische und 16
                Pooltische.
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ChronikKurz;
