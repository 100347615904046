import * as React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import { color } from "@mui/system";

// function ScrollMenue() {
const ScrollMenue = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    // <Box sx={{ flexGrow: 1, maxWidth: 480, bgcolor: "background.paper" }}>
    <Box
      sx={{
        flexGrow: 1,
        // maxWidth: { xs: 320, sm: 580 },
        maxWidth: { xs: 320, sm: "100%" },
        display: { xs: "none", md: "flex" },
        color: "default",
        marginTop: 0,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          color: "red",
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3, color: "transparent" },
          },
        }}
      >
        <Tab
          label={
            <NavLink className="text-gray-400" to="/">
              Home
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/unserAngebot">
              Unser Angebot
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/anfahrt">
              Anfahrt
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/kalender">
              Kalender
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/turniere">
              Turniere
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/livescoring">
              Livescoring
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/satzung">
              Satzung
            </NavLink>
          }
        />
        {/* <Tab
          label={
            <NavLink className="text-gray-400" to="/training">
              Training
            </NavLink>
          }
        />*/}
        <Tab
          label={
            <NavLink className="text-gray-400" to="/onlineRegistrierung">
              Online-Registrierung
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/contact">
              Kontakt
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/ChronikKurz">
              Chronik
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/vorstand">
              Vorstand
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/impressum">
              Impressum
            </NavLink>
          }
        />
        <Tab
          label={
            <NavLink className="text-gray-400" to="/datenschutz">
              Datenschutz
            </NavLink>
          }
        />
      </Tabs>
    </Box>
  );
};

export default ScrollMenue;
