import React from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import * as Components from "src/components";
import { NavLink } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import MailIcon from "@mui/icons-material/Mail";

const card1 = (
  <>
    <CardContent>
      <Typography sx={{ fontSize: 28, color: "black" }} >
          Unsere Online-Registrierung und Mitglieder-Selbstverwaltung
      </Typography>
    </CardContent>
  </>
);

const card2 = (
  <>
    <CardContent>
    <Typography sx={{ fontSize: 28, color: "black" }} gutterBottom>
        ... Neumitglieder
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
        Ihr möchtet unserem Verein beitreten und uns dafür vorab online unverbindlich eure Daten übermitteln?
          <a
              style={{ color: "red" }}
              href="https://m.netxp-verein.de/N/5330"
              target="_blank"
              rel="noopener"
          >{" "}Hier{" "} <LinkIcon /></a>
        {" "}habt ihr dazu die Möglichkeit.
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
        Die von euch erfassten Daten fließen in ein Antragsformular, dass ausgedruckt und euch bei einem Termin im Verein zur Unterschrift vorgelegt wird.
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
        Erst mit eurer Unterschrift wird also eure Mitgliedschaft zum Wunschtermin wirksam.
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
        <NavLink to="/unserAngebot" style={{ color: "red" }}>
          Einzelheiten zu den möglichen Beitragsstufen {" "} <LinkIcon />
        </NavLink>{" "}
        findet ihr hier auf unserer Webseite, werden euch aber gerne von unseren Vorstandsmitgliedern ausführlich erläutert.
      </Typography>
    </CardContent>
  </>
);

const card3 = (
  <>
    <CardContent>
    <Typography sx={{ fontSize: 28, color: "black" }} gutterBottom>
        ... Bestandsmitglieder  
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
      Euch wurde ein Registrierungscode an eure dem Verein bekannte E-Mail-Adresse übermittelt.
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
      Seid ihr den Hinweisen in dieser Mail gefolgt, könnt ihr euch
      <a
              style={{ color: "red" }}
              href="https://m.netxp-verein.de/Login"
              target="_blank"
              rel="noopener"
          >{" "}hier{" "} <LinkIcon /></a> mit eurem Account anmelden, die für euch hinterlegten Daten prüfen und ggf. ändern.
      </Typography>
      <Typography sx={{mb: 1, mt: 1,  color: "black" }}>
      Bei Problemen wendet euch bitte direkt an ein Vorstandsmitglied oder schickt eine      
        <a href={"mailto:info@pbv-pinneberg.de"} style={{ color: "red" }}>
        {" "}Mail{" "}<MailIcon />{" "} an info@pbv-pinneberg.de
        </a>
        
      </Typography>      
    </CardContent>
  </>
);

const OnlineRegistrierung: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card2}
            </Card>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card3}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OnlineRegistrierung;
