import React from "react";
//import { render } from "react-dom";
//import { CSSProperties } from "react";
import { NavLink } from "react-router-dom";
//import Link from "@mui/material/Link";
//import LinkIcon from '@mui/icons-material/Link';
import {
  Avatar,
  Box,
  Container,
  IconButton,
  //Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

// const footerStyle: CSSProperties = {
//   color: "default",
//   fontSize: "20px",
//   textAlign: "center",
//   padding: "20px",
//   position: "fixed",
//   left: "0",
//   bottom: "0",
//   height: "auto",
//   width: "100%",
// };

const Footer = () => {
  return (
    <>
      {/* <footer style={footerStyle}> */}
      <footer>
        <Container>
          <Typography className="grid lg:grid-cols-4 gap-4 place-items-center">
            <Box>
              <NavLink to="/impressum"
                  style={{ color: "red", textDecoration: 'underline' }}
                  className="text-gray-400">
                  IMPRESSUM
              </NavLink>  
            </Box>
            <Box>
              <NavLink to="/datenschutz"
                  style={{ color: "red", textDecoration: 'underline' }}
                  className="text-gray-400">
                  DATENSCHUTZ
              </NavLink>              
            </Box>
            <Box>© 2022 1. PBV Pinneberg e.V.</Box>
            <Box>
              <Tooltip title="Norddeutscher Billiard Verband">
                <IconButton onClick={() => window.open("https://ndbv.de/")}>
                  <Avatar
                    alt="Norddeutscher Billiard Verband"
                    src={require("src/files/nbv.png")}
                    variant="square"
                    sx={{ width: 42, height: 60 }}
                  >
                    <a
                      href="https://ndbv.de/"
                      target="_blank"
                      rel="noopener"
                    ></a>
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Typography>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
