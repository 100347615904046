// import  React from  'react';
import React, { useState } from "react";
//import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
// import satzungPDF from "src/files/Satzung.pdf";
import satzungPDF from "src/files/PBV_Satzung_MV 2024_clean.pdf";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import * as Components from "src/components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const card1 = (
  <>
    <CardContent>
      <Typography>
        <a
          href={satzungPDF}
          target="_blank"
          rel="noopener"
          style={{ color: "red" }}
        >
          Zum öffnen der Satzung im neuen Tab des Browsers bitte diesen Text
          anklicken.
        </a>
      </Typography>
    </CardContent>
  </>
);

const Satzung: React.VFC = () => {
  const [numPages, setNumPages] = useState<null | number>(null);
  // const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
    setNumPages(numPages);
  }

  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 1,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <PDFDocumentWrapper>
                <Document
                  file={satzungPDF}
                  options={{ workerSrc: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </PDFDocumentWrapper>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Satzung;
