//import React, { useState } from "react";
import React from "react";
//import Box from "@mui/material/Box";
import * as Components from "src/components";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  //Checkbox,
  Container,
} from "@mui/material";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
//import EmailIcon from "@mui/icons-material/Email";
import "yup-phone";
//import { oneOf } from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";

const initialValues = {
  // Vorname: "",
  // Nachname: "",
  // eMail: "",
  // Telefon: "",
  // Nachricht: "",
  Datenschutz: false,
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validation = yup.object().shape({
  Vorname: yup.string().ensure().required("Pflichtfeld"),
  Nachname: yup.string().ensure().required("Pflichtfeld"),
  eMail: yup
    .string()
    .ensure()
    .required("Pflichtfeld")
    .email("Falsches E-Mail Format")
    .min(6, "E-Mailadresse ist zu kurz"),
  Telefon: yup
    .string()
    .matches(phoneRegExp, "falsche Eingabe, Mustereingabe: 08154711")
    .required("Pflichtfeld"),
  // Telefon: yup.string().phone().required()
  Nachricht: yup
    .string()
    .ensure()
    .required("Pflichtfeld")
    .max(255, "Ihre Nachricht ist zu lang!"),
  Datenschutz: yup.bool().oneOf([true], " Datenschutz ist ein Pflichtfeld"),
});

const Contact: React.VFC = () => {
  const history = useNavigate();
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <CardContent>
              <Typography
                sx={{ fontSize: 28 }}
                color="text.secondary"
                gutterBottom
              >
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                  align="center"
                >
                  {/* <Card style={{ margin: "0 auto", padding: "20px 5px" }}> */}
                  <Card
                    style={{
                      maxWidth: "100%",
                      margin: "0 auto",
                      padding: "20px 5px",
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom variant="h2">
                        Ihre Nachricht an uns
                      </Typography>
                      <Typography gutterBottom variant="h5">
                        Was möchten Sie uns mitteilen?
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Füllen Sie das Formular aus und wir melden uns so
                        schnell es geht bei Ihnen.
                      </Typography>
                      <Formik
                        validationSchema={validation}
                        initialValues={{ initialValues }}
                        onSubmit={(values, { setSubmitting }) => {
                          // console.log(JSON.stringify(values, null, 2));
                          history("/");
                        }}
                      >
                        {({ values, isSubmitting }) => (
                          <Form>
                            <Grid container spacing={1}>
                              <Grid xs={12} sm={6} item>
                                <Field
                                  label="Vorname"
                                  name="Vorname"
                                  as={TextField}
                                  placeholder="... bitte Vornamen eingeben"
                                  variant="outlined"
                                  fullWidth
                                />
                                <ErrorMessage name="Vorname" />
                              </Grid>
                              <Grid xs={12} sm={6} item>
                                <Field
                                  label="Nachnamen"
                                  name="Nachname"
                                  as={TextField}
                                  placeholder="... bitte Nachnamen eingeben"
                                  variant="outlined"
                                  fullWidth
                                />
                                <ErrorMessage name="Nachname" />
                              </Grid>
                              <Grid xs={12} item>
                                <Field
                                  label="E-Mail"
                                  name="eMail"
                                  as={TextField}
                                  placeholder="... bitte E-Mail eingeben"
                                  variant="outlined"
                                  fullWidth
                                />
                                <ErrorMessage name="eMail" />
                              </Grid>
                              <Grid xs={12} item>
                                <Field
                                  label="Telefon"
                                  name="Telefon"
                                  as={TextField}
                                  placeholder="... bitte Telefonnummer eingeben"
                                  variant="outlined"
                                  fullWidth
                                />
                                <ErrorMessage name="Telefon" />
                              </Grid>
                              <Grid xs={12} item>
                                <Field
                                  label="Nachricht"
                                  name="Nachricht"
                                  as={TextField}
                                  multiline
                                  rows={2}
                                  placeholder="... ihre Nachricht an uns"
                                  variant="outlined"
                                  fullWidth
                                />
                                <ErrorMessage name="Nachricht" />
                              </Grid>
                              <Grid xs={12} item>
                                <Typography gutterBottom variant="h5">
                                  Datenschutzerklärung lesen
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  Bitte lesen sie hier über unseren{" "}
                                  <NavLink
                                    to="/datenschutz"
                                    style={{ color: "red" }}
                                  >
                                    Datenschutz.
                                  </NavLink>
                                </Typography>
                                <Field
                                  label="Datenschutzbestimmung zustimmen"
                                  type="checkbox"
                                  name="Datenschutz"
                                  required
                                />
                                <ErrorMessage name="Datenschutz" />
                              </Grid>
                              <Grid xs={12} item>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={() =>
                                    window.open(
                                      `mailto:info@pbv-pinneberg.de?subject=E-Mail%20an%20den%20PBV-Pinneberg&body=%0D%0A%0D%0AVorname:
                                      %0D%0A${values.Vorname}
                                      %0D%0A%0D%0ANachname:
                                      %0D%0A${values.Nachname}
                                      %0D%0A%0D%0ANachricht:
                                      %0D%0A${values.Nachricht}
                                      %0D%0A%0D%0AE-Mail:
                                      %0D%0A${values.eMail}
                                      %0D%0A%0D%0ARufnummer:
                                      %0D%0A${values.Telefon}
                                      %0D%0A%0D%0AViele%20Grüße:
                                      %0D%0A${values.Vorname}%20${values.Nachname}
                                      %0D%0A%0D%0A
                                      `
                                      // "mailto:email@example.com?subject=Subject&body=Body%20goes%20here"
                                    )
                                  }
                                  disabled={isSubmitting}
                                >
                                  absenden
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </CardContent>
                  </Card>
                </Typography>
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;

// function useForm(arg0: { resolver: any }): {
//   register: any;
//   handleSubmit: any;
//   reset: any;
//   errors: any;
// } {
//   throw new Error("Function not implemented.");
// }
