import React from "react";
import Iframe from "react-iframe";
import Card from "@mui/material/Card";
import { Container, Grid } from "@mui/material";
//import * as Components from "src/components";

const Kalender: React.VFC = () => {
  return (
    <>
      {/* <Components.PbvBild /> */}
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <p>Unser Veranstaltungskalender</p>
              <Iframe
                // url="https://calendar.google.com/calendar/embed?src=qk1503g431rkt6ptg9rsost7to%40group.calendar.google.com&ctz=Europe%2FAmsterdam"
                // url="https://joomla.pbv-pinneberg.de/"
                url="https://calendar.google.com/calendar/embed?src=895a22b25c9ecd6ccc93afad41166fcbcf69a0acc6a41128288c23b1a2d5962e%40group.calendar.google.com&ctz=Europe%2FBerlin"
                width="100%"
                height="840"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Kalender;
