import React from "react";
import Iframe from "react-iframe";
import MailIcon from "@mui/icons-material/Mail";
import { Card, Container, Grid } from "@mui/material";
import * as Components from "src/components";
import LinkIcon from "@mui/icons-material/Link";

const Livescoring: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <div className="text-center text-gray-400">
                <h1>
                  Unser Livescooring, Ergebnisse in Echtzeit verfolgen, derzeit
                  leider nur für Snooker.
                </h1>
              </div>
              {/* <Iframe
                url="http://billard-live.org/snooker/LiveScore_IN.php"
                width="100%"
                height="400px"
                display="block"
                position="relative"
              /> */}
              <a
                href="http://billard-live.org/snooker/LiveScore_IN.php"
                target="_blank"
                rel="noopener"
              >
                ... hier geht's zum Scoreboard <LinkIcon />
              </a>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Livescoring;
