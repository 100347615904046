const preise = {
  poolTagesmitgliedschaft: "15",
  snookerTagesmitgliedschaft: "15",
  tagesmitgliedschaft: "15",
  basisMitgliedschaft: "55",
  alleGetraenke: "1,50 € und 2,50 €",
  plusBeitrag: "65",
  karmaBeitrag: "75",
  grundBeitrag: "49",
  jugendlicheBis14Jahre: "19",
  jugendlicheBis18SchuelerAzubiStudenten: "30",
  familieJugendliche: "15",
  familieLebenspartner: "20",
  aufnahmeGebuehr: "49",
  startgeldFriday: "10",
};
export default preise;
