import React from "react";
import Iframe from "react-iframe";
import MailIcon from "@mui/icons-material/Mail";
import { Card, Container, Grid } from "@mui/material";
import * as Components from "src/components";

const Anfahrt: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              <div className="text-center text-gray-400">
                <h1>
                  1. PBV Pinneberg, Haderslebener Str. 4A, 25421 Pinneberg
                </h1>
                <h1>
                  <MailIcon />
                  <a href={"mailto:info@pbv-pinneberg.de"}>
                    info@pbv-pinneberg.de
                  </a>
                </h1>
                <h1>Tel.: +49 4101 568 33 01</h1>
              </div>
              <Iframe
                url="https://maps.google.com/maps?q=1.%20PBV%20Pinnberg,%20Haderslebener%20Str.%204A,%2025421%20Pinneberg&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="100%"
                height="400px"
                display="block"
                position="relative"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Anfahrt;
