import * as React from "react";
import { NavLink } from "react-router-dom";
//import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
//import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
//import ListItem from "@mui/material/ListItem";
//import ListItemIcon from "@mui/material/ListItemIcon";
//import ListItemText from "@mui/material/ListItemText";
//import InboxIcon from "@mui/icons-material/MoveToInbox";
//import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItem } from "@mui/material";

const Drawer = () => {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     {/* <List>
  //       {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>
  //             {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //           </ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List> */}
  //     <Divider />
  //     {/* <List>
  //       {["All mail", "Trash", "Spam"].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>
  //             {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //           </ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List> */}
  //   </Box>
  // );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {<MenuIcon color="default" />}
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onOpen={toggleDrawer(anchor, true)}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            // anchor={anchor}
            // open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
            // onOpen={toggleDrawer(anchor, true)}
          >
            <MenuItem>
              <NavLink className="text-gray-400" to="/">
                Home
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/unserAngebot">
                Unser Angebot
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/anfahrt">
                Anfahrt
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/kalender">
                Kalender
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/turniere">
                Turniere
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/livescoring">
                Livescoring
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/satzung">
                Satzung
              </NavLink>
            </MenuItem>
            <Divider />
            {/* <MenuItem>
              <NavLink className="text-gray-400" to="/training">
                Training
              </NavLink>
            </MenuItem>
      <Divider /> */}
            <MenuItem>
              <NavLink className="text-gray-400" to="/onlineRegistrierung">
                Online-Registrierung
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/contact">
                Kontakt
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/chronikKurz">
                Chronik
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/vorstand">
                Vorstand
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/impressum">
                Impressum
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <NavLink className="text-gray-400" to="/datenschutz">
                Datenschutz
              </NavLink>
            </MenuItem>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Drawer;
