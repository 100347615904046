import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
//import Menu from "@mui/material/Menu";
//import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
//import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
//import MenuItem from "@mui/material/MenuItem";
import * as Components from "src/components";
import { useNavigate } from "react-router-dom";

//const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar = () => {
  const history = useNavigate();
  //const [anchorElNav, setAnchorElNav] = React.useState(null);
  //const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenNavMenu = (event: any) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event: any) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <AppBar position="fixed" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <Tooltip title="PBV-Pinneberg, Norddeutschlands größter Billiardverein">
              <IconButton onClick={() => history("/")}>
                <Avatar
                  alt="PBV-Pinnberg Logo"
                  src={require("src/files/PbvLogo.png")}
                  variant="square"
                  sx={{ width: 60, height: 52 }}
                />
              </IconButton>
            </Tooltip>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Components.Drawer />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Tooltip title="PBV-Pinneberg, Norddeutschlands größter Billiardverein">
              <IconButton onClick={() => history("/")}>
                <Avatar
                  alt="PBV-Pinnberg Logo"
                  src={require("src/files/PbvLogo.png")}
                  variant="square"
                  sx={{ width: 60, height: 52 }}
                />
              </IconButton>
            </Tooltip>
          </Typography>
          {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              maxWidth: { xs: 320, sm: "85%" },
            }}
          >
            <Components.ScrollMenue />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Typography textAlign="center">
              <Tooltip title="PBV-Pinneberg, Norddeutschlands größter Billiardverein">
                <Avatar
                  alt="Billiard Kugel Nr. Neun"
                  src={require("src/files/neun.png")}
                  sx={{ width: 52, height: 52 }}
                />
              </Tooltip>
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
