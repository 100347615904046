import { useState } from "react";
import * as Components from "./components";
import * as Pages from "./pages";
import { Route, Routes } from "react-router-dom";

// Import MUI stuff
import "@fontsource/roboto";
import {
  Switch,
  Box,
  FormControlLabel,
  CssBaseline,
  ThemeOptions,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Define theme settings
const light: ThemeOptions = {
  palette: {
    mode: "light",
  },
};

const dark: ThemeOptions = {
  palette: {
    mode: "dark",
  },
};

const App = () => {
  // The light theme is used by default
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  // This function is triggered when the Switch component is toggled
  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <div>
      <ThemeProvider
        theme={isDarkTheme ? createTheme(dark) : createTheme(light)}
      >
        {/* Implementierung CssBasile für den DraktMode */}
        <CssBaseline />

        {/* Appbar mit dem Menü */}
        <Components.ResponsiveAppBar />

        {/* Box als Platzhalter, damit der Schalter für den DarkMode unter der AppBar sitzt */}
        {/* mit p={4} wird der Abstand von oben gesetzt */}
        <Box component="div" p={4}></Box>

        {/* Switsch für den DarkMode */}
        <FormControlLabel
          control={<Switch checked={isDarkTheme} onChange={changeTheme} />}
          label={isDarkTheme ? "Light Mode" : "Dark Mode"}
        />

        {/* Routen für die Seiten */}
        <Routes>
          <Route path="/" element={<Pages.Home />} />
          <Route path="/unserAngebot" element={<Pages.UnserAngebot />} />
          <Route path="/anfahrt" element={<Pages.Anfahrt />} />
          <Route path="/kalender" element={<Pages.Kalender />} />
          <Route path="/turniere" element={<Pages.Turniere />} />
          <Route path="/chronikKurz" element={<Pages.ChronikKurz />} />
          <Route path="/chronikLang" element={<Pages.ChronikLang />} />
          <Route path="/satzung" element={<Pages.Satzung />} />
          <Route path="/training" element={<Pages.Training />} />
          <Route path="/livescoring" element={<Pages.Livescoring />} />
          <Route
            path="/onlineRegistrierung"
            element={<Pages.OnlineRegistrierung />}
          />
          <Route path="/contact" element={<Pages.Contact />} />
          <Route path="/vorstand" element={<Pages.Vorstand />} />
          <Route path="/impressum" element={<Pages.Impressum />} />
          <Route path="/datenschutz" element={<Pages.Datenschutz />} />
        </Routes>

        {/* Footer */}
        <Components.Footer />
      </ThemeProvider>
    </div>
  );
};

export default App;
