import React from "react";
//import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import { Container, Grid } from "@mui/material";
import * as Components from "src/components";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

const card1 = (
  <>
    <CardContent>
      <Typography variant="h4" color={"black"} gutterBottom>
        <span className="font-bold">Impressum</span>
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">
          1. P.B.V. Pinneberg e.V., Haderslebener Straße 4a, 25421 Pinneberg
        </span>
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Telefon:</span>
        <br />
        +49 4101 5683301
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">E-Mail:</span>
        <br />
        <EmailIcon />
        <a href={"mailto:info@pbv-pinneberg.de"}>info@pbv-pinneberg.de</a>
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Internet:</span>
        <br />
        <a href="https://www.pbv-pinneberg.de" target="_blank">
          https://www.pbv-pinneberg.de
        </a>
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Vertretungsberechtigter Vorstand:</span>
        <br />
        Ralf Winkler, Haderslebener Straße 4a, 25421 Pinneberg
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">
          Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:
        </span>
        <br />
        Ralf Winkler, Haderslebener Straße 4a, 25421 Pinneberg
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Vereinsregisternummer:</span>
        <br />
        VR 989 PI
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Steuernummer:</span>
        <br />
        18 / 299 / 71215
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">UsT-IdNr.:</span>
        <br />
        DE 303 403 888
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Haftungshinweis:</span>
        <br />
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        <span className="font-bold">Vorstand:</span>
        <br />
        1. Vorsitzender: Ralf Winkler
        <br />
        2. Vorsitzender: Jan Bitter
        <br />
        Kassenwart: Michael Sturhan
        <br />
        Sportwart Pool: Frank Schmidt
        <br />
        Sportwart Snooker: Joaquim Macedo
      </Typography>
    </CardContent>
  </>
);

const Impressum: React.VFC = () => {
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
              }}
            >
              {card1}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Impressum;
