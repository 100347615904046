import * as React from "react";
import * as Components from "src/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import preise from "src/utils/preise";
//import Box from "@mui/material/Box";
// import { makeStyles } from "@mui/styles";
import { Container, Grid } from "@mui/material";
// import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
// import FastfoodIcon from "@mui/icons-material/Fastfood";
// import LocationCityIcon from "@mui/icons-material/LocationCity";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

const card1 = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 28, color: "black" }} gutterBottom>
        ... das bieten wir
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        16 Profi-Pool-Tische, bezogen mit Simones 860 Tuch.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        6 Snookertische der absoluten Extraklasse uvm.
      </Typography>
      <Typography variant="h5" component="div" color="black">
        Das ganze für moderate 55€ im Monat.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}></Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Vollmitglieder erhalten einen Rund-um-die-Uhr-Zugang zu den
        Vereinsräumlichkeiten.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Alle Getränke zwischen {preise.alleGetraenke}.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Günstige Verkehrsanbindung (direkt an der Ausfahrt PI-Nord der A23).
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Raucher-Lounge (Vereinsraum ist rauchfrei!).
      </Typography>
    </CardContent>
  </React.Fragment>
);

const card2 = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 28, color: "black" }} gutterBottom>
        ... Billiard ist Sport
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Liga-Sport (8 Mannschaften im NBV e.V.).
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        3 hausinterne Ligen.
      </Typography>
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        Jugendfördertraining
      </Typography> */}
      {/* <Typography sx={{ mb: 1.5, color: "black" }}>
        Billardschule für Einsteiger und Erwachsene
      </Typography> */}
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Austragungsort für diverse Norddeutsche Meisterschaften.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Austragungsort etlicher Großturniere.
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        regelmäßige Vereinsevents
      </Typography>
    </CardContent>
  </React.Fragment>
);

const card3 = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 28, color: "black" }} gutterBottom>
        ... die Preise / Beiträge
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Aufnahmegebühr € {preise.aufnahmeGebuehr},–
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Erwachsene: monatlich moderate € {preise.basisMitgliedschaft},–
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Unsere neue Beitragsstruktur ab dem 01.04.2023:
        <p>{preise.basisMitgliedschaft}€ - Standard-Beitrag</p>
        <p>{preise.plusBeitrag}€ - Plus-Beitrag</p>
        <p>{preise.karmaBeitrag}€ - Karma-Beitrag</p>
        <p>{preise.grundBeitrag}€ - Grund-Beitrag</p>
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Jugendliche bis 14 Jahre: monatlich nur € {preise.jugendlicheBis14Jahre}
        ,–
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Jugendliche von 14 – 18 Jahre, Schüler, Auszubildende und Studenten:
        monatlich nur € {preise.jugendlicheBis18SchuelerAzubiStudenten},–
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Familienbeitrag Jugendliche: € {preise.familieJugendliche},--
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Familienbeitrag Lebenspartner: € {preise.familieLebenspartner},--
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Pool für Gäste: Tagesmitgliedschaft € {preise.poolTagesmitgliedschaft}{" "}
        pro Person
      </Typography>
      <Typography sx={{ mb: 1.5, color: "black" }}>
        Snooker für Gäste: Tagesmitgliedschaft €{" "}
        {preise.snookerTagesmitgliedschaft} pro Person
      </Typography>
    </CardContent>
  </React.Fragment>
);

// const useStyles: any = makeStyles((theme: any) => ({
//   root: {
//     padding: theme.spacing(12, 4),
//   },
//   card: {
//     height: "100%",
//     width: "100%",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     border: "1px solid black",
//     borderRadius: "5px",
//     textAlign: "center",
//   },
//   icon: {
//     padding: theme.spacing(2, 0),
//   },
//   title: {
//     padding: theme.spacing(2),
//   },
//   featureList: {
//     padding: theme.spacing(2),
//   },
// }));

const UnserAngebot = () => {
  // const classes = useStyles();
  return (
    <>
      <Components.PbvBild />
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "lightgray",
                color: "black",
                height: 710,
              }}
            >
              {card1}
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "lightgray",
                color: "black",
                height: 710,
              }}
            >
              {card2}
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              variant="outlined"
              sx={{
                marginBottom: 2,
                backgroundColor: "lightgray",
                color: "black",
                height: 710,
              }}
            >
              {card3}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
    // <>
    //   <Container
    //     component="section"
    //     maxWidth="xl"
    //     className={Components.MySyles.root}
    //   >
    //     <Grid container spacing={3} alignItems="stretch">
    //       <Grid item xs={12} sm={12}>
    //         <div className={Components.MySyles.card}>
    //           <Card variant="outlined" sx={{ marginBottom: 2 }}>
    //             <img
    //               src={require("src/files/PBV_Header.png")}
    //               width="100%"
    //               height="auto"
    //             />
    //           </Card>
    //         </div>
    //       </Grid>
    //     </Grid>
    //   </Container>
    //   <Container
    //     component="section"
    //     maxWidth="xl"
    //     className={Components.MySyles.root}
    //   >
    //     <Grid container spacing={3} alignItems="stretch">
    //       <Grid item xs={12} sm={4}>
    //         <div className={Components.MySyles.card}>
    //           <Card
    //             variant="outlined"
    //             sx={{
    //               backgroundColor: "lightgray",
    //               color: "black",
    //               height: 710,
    //             }}
    //           >
    //             {card1}
    //           </Card>
    //         </div>
    //       </Grid>
    //       <Grid item xs={12} sm={4}>
    //         <div className={Components.MySyles.card}>
    //           <Card
    //             variant="outlined"
    //             sx={{
    //               backgroundColor: "lightgray",
    //               color: "black",
    //               height: 710,
    //             }}
    //           >
    //             {card2}
    //           </Card>
    //         </div>
    //       </Grid>
    //       <Grid item xs={12} sm={4}>
    //         <div className={Components.MySyles.card}>
    //           <Card
    //             variant="outlined"
    //             sx={{
    //               backgroundColor: "lightgray",
    //               color: "black",
    //               height: 710,
    //             }}
    //           >
    //             {card3}
    //           </Card>
    //         </div>
    //       </Grid>
    //     </Grid>
    //   </Container>
    // </>
  );
};

export default UnserAngebot;
